export default {
/**
   * The id of the current active instance of the app.
   * 
   * @param {Object} state
   * @param {String} payload
   * 
   * @returns {Void}
   */  
  setAppId: state => {
    return state.appId 
  },  
  
  /**
   * The partner's name.
   * 
   * @param {Object} state
   * 
   * @returns {String}
   */
  getPartnerName: state => {
    return state.partnerName
  },

  /**
   * The form config stored in the state.
   * 
   * @param {Object} state 
   * 
   * @returns {Object}
   */
  getFormConfig: state => {
    return state.formConfig
  },

  /**
   * The page context.
   * 
   * @param {Object} state 
   * 
   * @returns {Object}
   */
  getPageContext: state => {
    return state.pageContext
  },
  /**
   * The form variant from the form config in the state.
   * 
   * @param {Object} getters 
   * 
   * @returns {Object}
   */
   getFormVariant: state => {

    return state.formVariant
  },        

  /**
   * The steps present on the form config.
   * 
   * @param {Object} state 
   * @param {Object} getters 
   * 
   * @returns {Array}
   */
  getFormSteps: (state, getters) => {
    let formVariant = getters.getFormVariant

    if (! Object.prototype.hasOwnProperty.call(formVariant, 'steps')) {
      return []
    }

    return formVariant.steps
  },

  /**
   * The number of steps associated with the form config.
   * 
   * @param {Object} state 
   * @param {Object} getters 
   * 
   * @returns {Number}
   */
  getFormStepsCount: (state, getters) => {
    let formVariant = getters.getFormVariant

    if (! Object.prototype.hasOwnProperty.call(formVariant, 'steps')) {
      return 0  
    }

    return formVariant.steps.length
  },

  /**
   * The current step of the form.
   * 
   * @param {Object} state
   * 
   * @returns {Number}
   */
  getCurrentFormStep: state => {
    return state.steps.current
  },

  /**
   * The list of form fields associated with the form config.
   * 
   * Note: This will return the full list of fields across all steps.
   * 
   * @param {Object} state 
   * @param {Object} getters
   * 
   * @returns {Array}
   */
  getFormFields: (state, getters) => {
    let formVariant = getters.getFormVariant

    if (! Object.prototype.hasOwnProperty.call(formVariant, 'steps')) {
      return []
    }

    let fields = []

    formVariant.steps.forEach(stepFields => {
      fields.push(...stepFields)
    })

    return fields
  },

  /**
   * The programs associated with the form config.
   * 
   * @param {Object} state 
   * @param {Object} getters
   * 
   * @returns {Array}
   */
  getPrograms: (state, getters) => {
    let formConfig = getters.getFormConfig

    if (! Object.prototype.hasOwnProperty.call(formConfig, 'programs')) {
      return []
    }

    return formConfig.programs
  },

  /** 
   * The submit object to be sent with the RFI.
   * 
   * @param {Object} state
   * 
   * @returns {Object}
   */
  getSubmitObject: state => {
    return state.submit
  },

  /**
   * The submission URL for the form.
   * 
   * @param {Object} state
   * 
   * @returns {String}
   */
  getSubmissionUrl: state => {
    return state.submissionUrl
  },

  /**
   * The redirect URL for the form.
   * 
   * @param {Object} state
   * @param {Object} getters
   * 
   * @returns {String}
   */
  getRedirectUrl: (state, getters) => {
    let formVariant = getters.getFormVariant

    if (! Object.prototype.hasOwnProperty.call(formVariant, 'redirect')) {
      return ''
    }

    return formVariant.redirect
  },

  /** 
   * The currently selected country.
   * 
   * @param {Object} state
   * @param {Object} getters
   * 
   * @returns {String}
   */
  getSelectedCountry: (state, getters) => {
    let submitObject = getters.getSubmitObject

    if (! Object.prototype.hasOwnProperty.call(submitObject, 'country')) {
      return ''
    }

    return submitObject.country
  },

  /**
   * The currently selected program ID.
   * 
   * @param {Object} state
   * @param {Object} getters
   * 
   * @returns {String}
   */
  getSelectedProgramId: (state, getters) => {
    let submitObject = getters.getSubmitObject

    if (! Object.prototype.hasOwnProperty.call(submitObject, 'program')) {
      return ''
    }

    return submitObject.program
  },

  /**
   * The currently selected program.
   * 
   * @param {Object} state
   * @param {Object} getters
   * 
   * @returns {Object}
   */
  getSelectedProgram: (state, getters) => {
    let programId = getters.getSelectedProgramId
    let programs = getters.getPrograms

    let program = programs.filter(program => program.id === programId)[0] ?? {}

    return program
  },

  /**
   * The selected program's qualifier.
   * 
   * @param {Object} state
   * @param {Object} getters
   * 
   * @returns {Object}
   */
  getSelectedProgramQualifier: (state, getters) => {
    let program = getters.getSelectedProgram
    let qualifier

    try {
      qualifier = program.programQualifier.data
    } catch {
      qualifier = {}
    }

    return qualifier
  },

  /** 
   * The currently selected program filter.
   * 
   * @param {Object} state
   * 
   * @returns {Object}
   */
  getProgramsFilter: state => {
    return state.programsFilter
  },

  /** 
   * The fields that should not be rendered.
   * 
   * @param {Object} state
   * 
   * @returns {Array}
   */
  getHiddenFields: state => {
    return state.hiddenFields
  },

  /**
   * Get the variant types that should be retrieved by the page context, setting the string
   * as the page context
   * 
   * @returns {Object}
   */
  getVariantTypes() {
    return {default: 'thedefault', affiliate:'affiliate', abtest:'abtest'}
  },

  /**
   * Sets the last phone entry that passed validation.
   * 
   * @param {Object} state
   * @param {Array}  payload
   * 
   * @returns {Void}
   */
  getLastPhone: state => {
    return state.lastPhone
  },

  /**
   * Sets the last email entry that passed validation.
   * 
   * @param {Object} state
   * @param {Array}  payload
   * 
   * @returns {Void}
   */
  getLastEmail: state => {
    return state.lastEmail
  },

  /**
   * Sets the last zipcode entry that passed validation.
   * 
   * @param {Object} state
   * @param {Array}  payload
   * 
   * @returns {Void}
   */
  getLastZip: state => {
    return state.lastZip
  },      
  /** 
   * Get the state of whether form is being submitted.
   * 
   * @param {Object} state
   * 
   * @returns {Array}
   */
  getIsSubmitting: state => state.isSubmitting,

  /**
   * Gets the yup validation schema
   * 
   * @param {Object} state
   * @param {Array}  payload
   * 
   * @returns {Void}
   */
  getSchemaObject: state => state.schemaObject,      

  /**
 * Gets yup validation schema overrides
 * 
 * @param {Object} state
 * @param {Array}  payload
 * 
 * @returns {Void}
 */
  getSchemaOverrides: state => state.schemaOverrides,
  
  /** 
   * The available programs on the form.
   * 
   * @param {Object} state
   * 
   * @returns {Array}
   */
  getAvailablePrograms: state => state.availablePrograms,
  
  /**
   * Gets the dial code.
   * 
   * @param {Object} state
   * @param {Array}  payload
   * 
   * @returns {Void}
   */
  getDialCode: state => {
    return state.dialCode
  },  

}