import { object, string } from 'yup';
import * as customRules from '@/validation/rules'
import state from '@/store/state.js'

export default {
  /**
   * Creates a rules object to pass to the validation provider.
   * 
   * @param {Array} rules
   * 
   * @returns {Object}
   */
  createRulesObject(rules) {
    let ruleObject = {}

    rules.forEach(rule => {
      ruleObject[rule.name] = rule.parameters ?? true
    })

    return ruleObject
  },

  /**
   * Creates a messages object to pass to the validation provider.
   * 
   * @param {Array} rules
   * 
   * @returns {Object}
   */
  createMessagesObject(rules) {
    let messageObject = {}

    rules.forEach(rule => {
      messageObject[rule.name] = rule.message
    })
        
    return messageObject
  },

  /** 
   * The CSS classes to be applied for validation purposes.
   * 
   * @returns {Object}
   */
  fieldHasErrors(fieldname) {
    let errors = state.schemaObject.errors[state.schemaObject.lut[fieldname]]
    return !!errors && errors.length > 0
  },

  /** 
   * The CSS classes to be applied for validation purposes.
   * 
   * @returns {Object}
   */
  createValidationClasses(fieldname) {
    let errors = state.schemaObject.errors[state.schemaObject.lut[fieldname]]
    // if errors object does not exist validation has never been run
    // if errors is greater than zero then the field is invalid and vice versa
    return {
      'validated': !!errors,
      'valid': !!errors && errors.length <= 0,
      'invalid': !!errors && errors.length > 0
    }
  },

  //store the validation message
  validationMessage: {},

  /**
   * Save the validation response from leads pipeline
   * 
   * @param {Array} rules
   * 
   * @returns {Object}
   */  
  setValidationMessage(key, message) {
    let messageSplit = message.split(":") //break the object response into two parts
    let messageClean = messageSplit[1].replace(/[^a-zA-Z0-9 ]/g, '').trim() //strip of non-alpha-num chars
    this.validationMessage[key] = messageClean
  },

  /**
   * Retrieve the validation response from leads pipeline
   * 
   * @param {Array} rules
   * 
   * @returns {Object}
   */  
  getValidationMessage(key) {
    return this.validationMessage[key] ?? 'The field is invalid'
  },

  /**
   * Use form steps to generate individual schemas
   * 
   * @param {Array} steps
   * 
   * @returns {Array}
   */
  generateStepsSchema(steps){
    var schemaArray = []
    var labelLut = {}

    steps.forEach((step, idx) => {
      let parentObjectParams = {}

      step.forEach((field) => {
        if(field.component === 'fr-programs-filter'){
          labelLut[field.component] = 'programsFilter'
        } else {
          labelLut[field.component] = field.meta.submitKey
        }
        
        let so = string()

        if(!!field.meta.ignoreValidation) { return } //if the validation should be ignored/field is hidden, skip it
        
         if (field.meta.rules.length > 0) {
          field.meta.rules.forEach((rule) => {
            switch(rule.name) {
              case 'min':
                length = rule.parameters.hasOwnProperty('length') ? rule.parameters.length : 2
                so = so.min(length, rule.message)
                break
              case 'max':
                length = rule.parameters.hasOwnProperty('length') ? rule.parameters.length : 40
                so = so.max(length, rule.message)
                break
              case 'required':
                so = so.when('$skipRequired', ([skipRequired], schema) => {
                  if (!!state.schemaOverrides.fields[field.component] && !state.schemaOverrides.fields[field.component].required){
                    skipRequired = true
                  }

                  // aveline please forgive us
                  if(field.component.toLowerCase().includes("qualifier")){
                    return !!skipRequired ? schema : schema.oneOf([true, "true"], rule.message)
                  } else {
                    return !!skipRequired ? schema : schema.required(rule.message)
                  }
                })
                break
              case 'email':
                so = so.test({
                  name: 'email',
                  test: async (value, { createError }) => {
                    if (!await customRules.email(value)) {
                      return createError({ message: this.getValidationMessage('email')})
                    }
                    else return true;
                  }
                })
                break
              case 'phone':
                so = so.test('phone', 'Must be a valid phone number', (value) => {
                  return customRules.phone(value)
                })
                break
              case 'postal_code':
                so = so.test('postal_code', 'Must be a valid postal code', (value) => {
                  return customRules.postal_code(value)
                })
                break
                case 'location':
                  so = so.test('location', 'Select from autocomplete dropdown', (value) => {
                    return customRules.location(value)
                  })
                  break                
              default:
            }
          })
          if(field.component === 'fr-programs-filter'){
            parentObjectParams['programsFilter'] = so 
          } else {
            parentObjectParams[field.meta.submitKey] = so 
          }
          
        }        
      })

      if (!schemaArray[idx]) { schemaArray[idx] = [] }
      schemaArray[idx] = object(parentObjectParams)
    })

    return { schema: schemaArray, labelLut: labelLut }
  },

  /**
   * Logic to load field config and set ignore validation
   * 
   * @param {String} fieldId
   * @param {Boolean} visible
   * 
   * @returns {Array}
   */
  setIgnoreValidation(fieldId, visible) {
    let formVariant = state.formVariant
    let currentStep = state.steps.current
    let steps = []

    if (! Object.prototype.hasOwnProperty.call(formVariant, 'steps')) {
      steps = []
    }

    steps = formVariant.steps
    let fieldObj = steps[currentStep - 1].find(field => field.id == fieldId)

    fieldObj.meta.ignoreValidation = visible

    return steps 
  }
}