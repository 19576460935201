export default {
  /**
   * Sets the id of the current active instance of the app.
   * 
   * @param {Object} state
   * @param {String} payload
   * 
   * @returns {Void}
   */  
    setAppId: (state, payload) => {
      state.appId = payload
    },

  /**
   * Sets the partner's name.
   * 
   * @param {Object} state
   * @param {String} payload
   * 
   * @returns {Void}
   */
  setPartnerName: (state, payload) => {
    state.partnerName = payload
  },

   /**
   * Sets the embed id.
   * 
   * @param {Object} state
   * @param {String} payload
   * 
   * @returns {Void}
   */
  setEmbedId: (state, payload) => {
      state.embedId = payload
    },
  
  /**
   * Sets the form config to the given value.
   * 
   * @param {Object} state 
   * @param {Object} payload 
   * 
   * @returns {Void}
   */
  setFormConfig: (state, payload) => {
    state.formConfig = payload
  },

  /**
   * Sets the page context to the given value.
   * 
   * @param {Object} state 
   * @param {Object} payload 
   * 
   * @returns {Void}
   */
  setPageContext: (state, payload) => {
    state.pageContext = payload
  },

  /**
   * Sets the submission URL for the form.
   * 
   * @param {Object} state
   * @param {String} payload
   * 
   * @returns {Void}
   */
  setSubmissionUrl: (state, payload) => {
    state.submissionUrl = payload
  },

  /**
   * Sets the email validation URL for the form.
   * 
   * @param {Object} state
   * @param {String} payload
   * 
   * @returns {Void}
   */
  setEmailValidationUrl: (state, payload) => {
    state.emailValidationUrl = payload
  },

  /**
 * Sets the phone validation URL for the form.
 * 
 * @param {Object} state
 * @param {String} payload
 * 
 * @returns {Void}
 */
  setPhoneValidationUrl: (state, payload) => {
    state.phoneValidationUrl = payload
  },

  /**
   * Sets the zip validation URL for the form.
   * 
   * @param {Object} state
   * @param {String} payload
   * 
   * @returns {Void}
   */
  setZipValidationUrl: (state, payload) => {
    state.zipValidationUrl = payload
  },

  /**
   * Sets the current step of the form.
   * 
   * @param {Object} state 
   * @param {Object} payload 
   * 
   * @returns {Void}
   */
  setCurrentFormStep: (state, payload) => {
    state.steps.current = payload
  },

  /**
   * Updates the submit object.
   * 
   * @param {Object} state
   * @param {Object} payload
   * 
   * @returns {Void}
   */
  updateSubmitObject: (state, payload) => {
    // this supposedly should be good enough to track reactivity in Vue 3
    state.submit[payload.key] = payload.value
  },

  /**
   * Sets the programs filter.
   * 
   * @param {Object} state 
   * @param {Object} payload 
   * 
   * @returns {Void}
   */
  setProgramsFilter: (state, payload) => {
    state.programsFilter = payload
  },

  /**
   * Sets the fields that should not be rendered.
   * 
   * @param {Object} state
   * @param {Array}  payload
   * 
   * @returns {Void}
   */
  setHiddenFields: (state, payload) => {
    state.hiddenFields = payload
  },

  /**
   * Sets the fields that should not be rendered.
   * 
   * @param {Object} state
   * @param {Array}  payload
   * 
   * @returns {Void}
   */
   setFormVariant: (state, payload) => {
    state.formVariant = payload
  },

  /**
   * Sets the last phone entry that passed validation.
   * 
   * @param {Object} state
   * @param {Array}  payload
   * 
   * @returns {Void}
   */
  setLastPhone: (state, payload) => {
    state.lastPhone = payload
  },

  /**
   * Sets the last email entry that passed validation.
   * 
   * @param {Object} state
   * @param {Array}  payload
   * 
   * @returns {Void}
   */
  setLastEmail: (state, payload) => {
    state.lastEmail = payload
  },


  /**
   * Sets the last zipcode entry that passed validation.
   * 
   * @param {Object} state
   * @param {Array}  payload
   * 
   * @returns {Void}
   */
  setLastZip: (state, payload) => {
    state.lastZip = payload
  },  

  /**
   * Sets the isSubmitting state.
   * 
   * @param {Object} state
   * @param {Array}  payload
   * 
   * @returns {Void}
   */

  setSubmittingState(state, payload) {
    state.isSubmitting = payload;
  },

  /**
   * Sets the yup validation schema
   * 
   * @param {Object} state
   * @param {Array}  payload
   * 
   * @returns {Void}
   */

  setSchemaObject(state, payload) {
    state.schemaObject = payload;
  },

  /**
   * Sets a field on the yup validation schema object
   * 
   * @param {Object} state
   * @param {Array}  payload
   * 
   * @returns {Void}
   */

  setSchemaObjectValue(state, payload) {
    state.schemaObject[payload.key] = payload.value;
  },

  /**
   * Sets yup validation schema overrides
   * 
   * @param {Object} state
   * @param {Array}  payload
   * 
   * @returns {Void}
   */
  setSchemaOverrides(state, payload) {
    state.schemaOverrides = payload;
  },

  /**
   * Sets available programs array
   * 
   * @param {Object} state
   * @param {Array}  payload
   * 
   * @returns {Void}
   */
  setAvailablePrograms(state, payload) {
    state.availablePrograms = payload;
  },

  /**
   * Sets the dial code.
   * 
   * @param {Object} state
   * @param {Array}  payload
   * 
   * @returns {Void}
   */
  setDialCode: (state, payload) => {
    state.dialCode = payload
  },    

}