import {createApp, h} from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import helpers from './helpers/helpers'
import FormRendererStore from './store'
import FormRenderer from './FormRenderer.vue'
import AppPlugin from './app.js'

const store = new Vuex.Store(FormRendererStore)
window.FreyaFormManager = class FreyaFormManager {
  /**
   * Creates a new instance of FreyaFormManager.
   * 
   * This will bootstrap Freya Form Renderer and all of the necessary components.
   * 
   * @param {Object}  [config]       The root configuration object
   * @param {Boolean} [config.debug] Whether or not the developer tools should be enabled
   * 
   * @returns {Void}
   * 
   * @memberof FreyaFormManager
   */
  constructor(config = {}) {
  }

  /**
   * Fetches the JSON configuration from the Sites API.
   * 
   * @param {Object} config           The root configuration object
   * @param {String} config.formId    The id of the form that should be rendered
   * @param {String} [config.baseUrl] The base url that will be used to fetch the form configuration
   * @param {String} config.embedId   The embed id for the associated form
   * 
   * @returns {Promise}
   * 
   * @memberof FreyaFormManager
   */
  async _fetchFormConfig(config) {
    if (!('formId' in config)) {
      throw new Error('Freya Form Renderer: No form id was provided. Please specify a form id to continue.')
    }

    let productionUrl = 'https://content.edu.help/v2/form'
    let baseUrl = config.baseUrl || productionUrl
    let locationURL = new URL(window.location.href)

    if(baseUrl != productionUrl){
      // set the QA URLs here
      store.dispatch('setEmailValidationUrl', 'https://rfi.qa.edu.help/v1/validate?field=email')
      store.dispatch('setPhoneValidationUrl', 'https://rfi.qa.edu.help/v1/validate?field=phone')
      store.dispatch('setZipValidationUrl', 'https://rfi.qa.edu.help/v1/validate?field=zip')
    }

    return await axios.get(`${baseUrl.replace(/\/+$/, '')}/${config.formId}?eid=${config.embedId}&locationURL=${locationURL.origin}${locationURL.pathname}`)
  }

  /**
   * Create a new instance of the form and bind it to the specified element.
   * 
   * Minimum required fields on the config object are;
   * 
   * `formId`
   * `partnerName` 
   * `accountId`
   * 
   * @param {Object}  config                    The root configuration object
   * @param {String}  config.formId             The id of the form that should be rendered
   * @param {String}  config.partnerName        The desired display name for the partner
   * @param {String}  config.accountId          The uuid for the associated partner's account
   * @param {String}  config.embedId            The embedId for the associated form.
   * @param {String}  [config.pageContext]      The variant to be loaded; default loaded if not specified
   * @param {String}  [config.groups]           The program grouping to be loaded
   * @param {Boolean} [config.isLandingPage]    Whether or not the form is for a landing page
   * @param {Boolean} [config.isSingleProgram]  Whether or not the form should be a single program variant and should hide the programs field
   * @param {String}  [config.defaultProgramId] The default program to be used when the form is rendered
   * @param {String}  [config.element]          The element that the form should be bound to. Must include the element descriptor (#, .)
   * @param {String}  [config.baseUrl]          The base url that will be used to fetch the form configuration
   * 
   * @returns {Promise}
   * 
   * @memberof FreyaFormManager
   */
  async create(config) {
    if (!('formId' in config)) {
      throw new Error('Freya Form Renderer: No form id was provided. Please specify a form id to continue.')
    }

    let formConfig

    try {
      // Extract the `data` parameter from the response and assign it to the `formConfig` variable defined above.
      ({ data: formConfig } = await this._fetchFormConfig({
        formId: config.formId,
        baseUrl: config.baseUrl || '',
        embedId: config.embedId
      }))

    } catch (errors) {
      throw new Error('Freya Form Renderer: There was a problem retrieving the specified form.')
    }

    function createNewInstance(id){
      const form = createApp({
        created() {
          this.$store.dispatch('initializePlugin', {
            ...config,
            formConfig,
            appId: id,
          })
        },
        mounted() {
          let utmScript = document.createElement('script')
          utmScript.setAttribute('src', 'https://freya.embed.edu.help/utilities/attribution.js')
          document.head.appendChild(utmScript)

          const evt = new Event("freya-renderer-form-created");
          document.dispatchEvent(evt);
        },
        render: () => h(FormRenderer),
      })

      let queryParameters = helpers.parseQueryParameters(window.location.search.substring(1).replace(/&&/, '&'))
      // .devtools is not exposed in Vue 3
      form.config.devtools = config.debug ?? queryParameters.debug ?? false

      form.use(store)

      // We need to expose all of the field components globally so that they can be referenced dynamically.
      let FormFieldComponents = require.context('./components/fields', true, /.vue$/)
      // Register each of the field components globally.
      FormFieldComponents.keys().forEach(filename => {
        let module = FormFieldComponents(filename)
        let moduleName = filename.replace(/(^.*[\\/])|(.vue)/ig, "") // Strip out all but the filename.
          .replace(/([a-z])([A-Z])/g, "$1-$2") // Convert to kebab-case
          .toLowerCase()

        form.component(moduleName, module.default || module)
      })

      form.use(AppPlugin)

      return form
    }


    // we're going to be using the existing element property to support multiple instances of a form on one page
    // for support of embeds and other resources that contain a single element, this piece of code will remain
    if (!!config.element && config.element.split(',').length > 0) {
      config.element.split(',').forEach(elem => {
        let form = createNewInstance(elem)
        form.mount(elem)
      });
    }
    else { 
      let form = createNewInstance(config.element || '#wes-form')
      form.mount(config.element || '#wes-form') 
    }

  }
}

if(window.FreyaFormManager) {

  const evt = new Event("freya-renderer-loaded");
  document.dispatchEvent(evt);
}

window.entryId = helpers.generateEntryId()