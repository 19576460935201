<template>
  <div 
    v-if="visible"
    :class="{...cssClasses}"
  >
    <fr-label
      for-id="fr-phone"
      class=""
    >
      {{ label || field.meta.label }}
    </fr-label>
    <IntlTelInput
      v-if="userCountry"
      id="fr-phone"
      ref="inputField"
      v-model="fieldValue"
      class="fr-input"
      name="fr-phone"
      :options="{
        nationalMode: false,
        separateDialCode: true,
        autoPlaceholder: 'off',
        countryOrder: ['US', 'CA', 'GB', 'AU', 'NZ', 'ZA'],
        initialCountry: userCountry,
      }"
      @blur="handleBlur"
      @change-country="onCountryChange"
    />
    <fr-field-error v-if="schemaHasErrors()">
      {{ errors() }}
    </fr-field-error>
  </div>
</template>

<script>
import FrLabel from "@/components/fields/base/FrLabel.vue";
import FrFieldError from "@/components/fields/base/FrFieldError"
import helpers from "@/helpers/helpers";
import { mapGetters, mapActions } from "vuex";
import { nextTick } from "vue";
import IntlTelInput from "intl-tel-input/vueWithUtils";
import "intl-tel-input/build/css/intlTelInput.css";
import axios from "axios";
import validation from '@/helpers/validation'

export default {
  name: "FrPhone",

  components: {
    FrLabel,
    FrFieldError,
    IntlTelInput
  },

  props: {
    /**
     * The form field that is being rendered.
     */
    field: {
      type: Object,
      required: true,
    },

    /**
     * The value that is passed from the parent component through `v-model`.
     */
    modelValue: {
      type: [Array, Object, String, Number, Boolean],
      default: undefined
    }
  },

  emits: ['update:modelValue', 'blur'],

  data() {
    return {
      iti: {},
      input: {},
      userCountry: null,
      interacted: false,
    };
  },

  computed: {
    /**
     * The getters mapped from Vuex.
     */
    ...mapGetters({
      formFields: "getFormFields",
      submitObject: "getSubmitObject",
      programsFilter: "getProgramsFilter",
      formVariant: "getFormVariant",
      lastPhone: "getLastPhone",
      schemaObject: "getSchemaObject",
      currentFormStep: "getCurrentFormStep",
      dialCode: "getDialCode"
    }),

    /**
     * The CSS classes to be applied to the element.
     *
     * This is defined as a computed property so we can dynamically set classes.
     *
     * @returns {Array}
     */
    cssClasses() {
      return {
        "fr-phone": true,
      };
    },

    /**
     * The value that is passed from the parent component through `v-model`.
     *
     * This is wrapped as a computed property so that it may be bound
     * as a `v-model` to a child component. Setting this up as a proxy
     * bypasses the `Avoid mutating a prop directly` error thrown by Vue.
     * Instead, we intercept this mutation and pass it along to the parent.
     *
     * @param {String} val
     *
     * @returns {String}
     */
    fieldValue: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      },
    },

    /**
     * For dynamic display of fields
     * @returns {Boolean}
     */
    visible() {
      // if there is no visibility property, show the field
      // if the visibility array is empty or not set, show the field
      // if the visibilty array has an object in it, and the toggle is show, and the match condition is and, only return true if all conditions match
      // if the visibilty array has an object in it, and the toggle is show, and the match condition is or, only return false if no conditions match
      // if the visibilty array has an object in it, and the toggle is hide, and the match condition is and, only return false if all conditions match
      if (!this.field.meta.visibility) {
        return true;
      }

      if (this.field.meta.visibility.length === 0) {
        return true;
      }
      if (
        this.field.meta.visibility.length > 0 &&
        this.field.meta.visibility[0].toggle === "show"
      ) {
        return helpers.conditionalLogicEvaluation(
          "visibility",
          this.formFields,
          this.field.meta,
          this.submitObject,
          this.programsFilter
        );
      }
      if (
        this.field.meta.visibility.length > 0 &&
        this.field.meta.visibility[0].toggle === "hide"
      ) {
        return !helpers.conditionalLogicEvaluation(
          "visibility",
          this.formFields,
          this.field.meta,
          this.submitObject,
          this.programsFilter
        );
      }
      //just return true as a default emergency escape case
      return true;
    },

    /**
     * For dynamic label settings
     * @returns {String}
     */
    label() {
      //if no attribute, return null for the default form config val to display
      if (!this.field.meta.display) {
        return null;
      }

      if (this.field.meta.display.length === 0) {
        return null;
      }
      //if length, if display name is not null or an empty string, else default
      if (
        this.field.meta.display.length > 0 &&
        this.field.meta.display[0].displayName &&
        this.field.meta.display[0].displayName !== ""
      ) {
        //if conditional logic evaluates to true
        if (
          helpers.conditionalLogicEvaluation(
            "display",
            this.formFields,
            this.field.meta,
            this.submitObject,
            this.programsFilter
          )
        ) {
          return this.field.meta.display[0].displayName;
        }
        return null;
      }

      return null;
    },
  },

  watch: {
    visible: {
      handler: function (val) {
      let steps = validation.setIgnoreValidation(this.field.id, !val)
      const { schema, labelLut } = validation.generateStepsSchema(steps)
      this.setSchemaObjectValue({key: "schema", value: schema})

        if(!val){
          this.fieldValue = this.field.meta.default ?? ''
        }
      },
    },

    async userCountry() {
      await nextTick() //wait for the next tick
      //when the user country is changed, we can pull in the dial code on init
      //the user country will only change from null to the user's country once
      this.setDialCode(this.$refs.inputField?.instance?.getSelectedCountryData()?.dialCode)
      this.$refs.inputField?.$el?.style.setProperty('--iti-fr-dial-chars', this.dialCode.length)
      
    },
   
  },

  mounted() {
  },


  created() {
    let steps = validation.setIgnoreValidation(this.field.id, !this.visible)
    const { schema, labelLut } = validation.generateStepsSchema(steps)
    this.setSchemaObjectValue({key: "schema", value: schema})

    //get the result of the user country and pipe it to the vue component
    this.getUserCountry().then((result) => {
        this.userCountry = result

    })
  },

  methods: {
    /**
     * The actions mapped from Vuex.
     */
     ...mapActions({
      setLastPhone: 'setLastPhone',
      setSchemaObjectValue: 'setSchemaObjectValue',
      setDialCode: 'setDialCode'
    }),
    
    /**
     * The blur-only interaction mode
     */
    // async handleBlur(validation) {
    async handleBlur() {
      if(this.lastPhone != this.fieldValue) {
        this.setLastPhone(this.fieldValue)
      }
      this.formatPhoneNumber() 
      this.interacted = true;

      this.schemaObject.errors[this.schemaObject.lut["fr-phone"]] = []
      this.validate()
  
      this.$emit('blur')

      
    },

    async getUserCountry() {
      try {
        let response = await axios.get("https://geo-service.risepoint.com/");
        //if there is a response, and it has a length of 2, use it
        if(response.data && response.data.length === 2) {
          return response.data.toLowerCase()
        }
        //return us by default
        return "US";
      } catch (err) {
        console.error(err);
      }
    },

    fieldHasErrors(name) {
      return validation.fieldHasErrors(name)
    },
    focus() {
      this.$refs.inputField.focus();
    },
    formatPhoneNumber() {
      var c_code = ""
      var formattedPhoneNumber = this.$refs.inputField?.instance?.getNumber()

      if (this.dialCode) {
        c_code = "+" + this.dialCode
      }

      formattedPhoneNumber = formattedPhoneNumber.replace(c_code + " ", "");
      formattedPhoneNumber = formattedPhoneNumber.replace(c_code, "");
      formattedPhoneNumber = formattedPhoneNumber.replaceAll("+", "");

      this.input.value = formattedPhoneNumber

    },

    isTrue(value) {
      return value === true || (typeof value === "string" && value.toLowerCase() === "true");
    },

    onCountryChange() {
      this.setDialCode(this.$refs.inputField?.instance?.getSelectedCountryData()?.dialCode)
      this.$refs.inputField?.$el?.style.setProperty('--iti-fr-dial-chars', this.dialCode.length)

    },

    errors() {
      return this.schemaObject.errors[this.schemaObject.lut["fr-phone"]][0]
    },

    schemaHasErrors() {
      return !!this.schemaObject?.errors[this.schemaObject.lut["fr-phone"]]
        && this.schemaObject?.errors[this.schemaObject.lut["fr-phone"]].length > 0
    },
    async validate() {
      await nextTick() // otherwise this.modelValue will lag behind
      //check if that field exists in the schema first
      if(this.schemaObject.lut["fr-phone"] in this.schemaObject.schema[this.currentFormStep - 1].fields) {
        this.schemaObject.schema[this.currentFormStep - 1].fields[this.schemaObject.lut["fr-phone"]].validate(this.$refs.inputField?.instance?.getNumber()).then(result => {
        this.schemaObject.errors[this.schemaObject.lut["fr-phone"]] = []
        }).catch(err => {
          this.schemaObject.errors[this.schemaObject.lut["fr-phone"]] = [err.message]
        })
      }
    },    
  },
};
</script>

<style lang="scss">

.iti__tel-input {
  width: 100%;
  margin-bottom: 0.313rem;
  padding: 0.313rem;
}

.iti__country-list {
  width: 100%;
  white-space: normal;
  color: #000;
  font-size: 0.813rem;
  z-index: 3;
}

.iti__selected-flag {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
}

.iti__selected-dial-code {
  color: #000;
  font-size: 0.875rem;
}

.iti {
  width: 100%;
  transition: box-shadow ease 0.25s;

  &:focus {
    outline: none;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  }
}

#fr-phone {
  padding-top: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  margin-bottom: 5px;
  width: 100%;

  transition: box-shadow ease 0.25s;
  border: 1px solid rgba(0, 0, 0, 0.5);

  &:focus {
    outline: none;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  }
}

.iti__flag-container {
  position: static;
  padding-top: 0px;
  padding-bottom: 0px;
  width: 50px;
}

:is(#fr-phone, .iti__tel-input) {
  --iti-fr-button-width: calc(var(--iti-flag-width) + (var(--iti-arrow-padding) * 2) + 6px + var(--iti-spacer-horizontal));
  padding-left: calc(var(--iti-fr-button-width) + 6px + (var(--iti-fr-dial-chars) * 1em)) !important;
}
</style>